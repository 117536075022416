var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "quizFormRef",
    staticClass: "create-quiz-form"
  }, [_c("a-modal", _vm._b({
    attrs: {
      title: _vm.title,
      visible: _vm.visible,
      closable: _vm.closable,
      maskClosable: _vm.maskClosable,
      width: _vm.width,
      getContainer: function getContainer() {
        return _vm.$refs.quizFormRef;
      },
      afterClose: _vm.afterClose
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, "a-modal", _vm.$attrs, false), [_c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("a-form-model", {
    ref: "quizForm",
    staticClass: "modal-wrapper",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      layout: "vertical",
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "难易等级：",
      prop: "difficultylevel"
    }
  }, [_c("a-select", {
    attrs: {
      disabled: !_vm.isedit,
      placeholder: "请选择难易等级"
    },
    model: {
      value: _vm.formData.difficultylevel,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "difficultylevel", $$v);
      },
      expression: "formData.difficultylevel"
    }
  }, _vm._l(_vm.difficultylevelList, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "题干：",
      prop: "question"
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "请输入题干",
      disabled: !_vm.isedit,
      "auto-size": {
        minRows: 1,
        maxRows: 10
      }
    },
    model: {
      value: _vm.formData.question,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "question", $$v);
      },
      expression: "formData.question"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "答案选项：",
      prop: "options"
    }
  }, [_c("div", {
    staticClass: "answer-list"
  }, _vm._l(_vm.formData.options, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "answer-item"
    }, [_c("a-input", {
      attrs: {
        placeholder: "请输入答案内容",
        disabled: !_vm.isedit,
        title: item.content
      },
      model: {
        value: item.content,
        callback: function callback($$v) {
          _vm.$set(item, "content", $$v);
        },
        expression: "item.content"
      }
    }, [_c("span", {
      attrs: {
        slot: "addonBefore"
      },
      slot: "addonBefore"
    }, [_vm._v(_vm._s(item.id))]), _vm.isedit && _vm.formData.options.length >= 3 ? _c("a-icon", {
      staticStyle: {
        "font-size": "16px",
        color: "red",
        cursor: "pointer"
      },
      attrs: {
        slot: "addonAfter",
        type: "minus-circle"
      },
      on: {
        click: function click($event) {
          return _vm.handleReduce(item.id);
        }
      },
      slot: "addonAfter"
    }) : _vm._e()], 1), _vm.formData.options.length == index + 1 && _vm.isedit && _vm.formData.options.length < 4 ? _c("a-icon", {
      staticClass: "icon-plus",
      staticStyle: {
        "font-size": "16px",
        cursor: "pointer"
      },
      attrs: {
        type: "plus-circle"
      },
      on: {
        click: _vm.handleAdd
      }
    }) : _vm._e()], 1);
  }), 0)]), _c("a-form-model-item", {
    attrs: {
      label: "正确答案：",
      prop: "answer"
    }
  }, [_c("a-select", {
    attrs: {
      disabled: !_vm.isedit,
      placeholder: "请选择正确答案"
    },
    model: {
      value: _vm.formData.answer,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "answer", $$v);
      },
      expression: "formData.answer"
    }
  }, _vm._l(_vm.formData.options, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.id) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "禁用卡片：",
      prop: "istakeeffect"
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: !_vm.isedit
    },
    model: {
      value: _vm.formData.forbiddencard,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "forbiddencard", $$v);
      },
      expression: "formData.forbiddencard"
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v(" 是 ")]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v(" 否 ")])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "状态设置：",
      prop: "istakeeffect"
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: !_vm.isedit
    },
    model: {
      value: _vm.formData.validstatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "validstatus", $$v);
      },
      expression: "formData.validstatus"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 已生效 ")]), _c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 未生效 ")])], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };